<template>
  <div v-if="canAccessPage">
    <v-row>
      <v-col cols="12" sm="12" class="pt-0">
        <estrutura-usuario-tabela
          style="outline:none;"
          @ESTRUTURAUSUARIOTABELA_EDITAR="editar"
          @ESTRUTURAUSUARIOTABELA_VISUALIZAR="visualizar"/>

        <v-btn
          v-if="canPost"
          fab
          right
          bottom
          fixed
          @click.native="$router.push({ name: 'novoEstruturaUsuario' })"
          class="v-btn__fab"
          color="primary">
          <v-icon>add</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { generateComputed } from '../../common/functions/roles-computed-generator';
import EstruturaUsuarioTabela from './EstruturaUsuarioTabela';

export default {
  name: 'EstruturaUsuarioList',
  data() {
    return {
      resourcesProduto: this.$api.produto(this.$resource),
    };
  },
  components: {
    EstruturaUsuarioTabela,
  },
  computed: {
    ...generateComputed('ESTRUTURA', [
      'canAccessPage',
      'canPost',
      'canSearch',
    ]),
  },
  methods: {
    editar(item) {
      this.$router.push({ name: 'editarEstruturaUsuario', params: { id: item.id } });
    },
    visualizar(item) {
      this.$router.push({ name: 'verEstruturaUsuario', params: { id: item.id } });
    },
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast('Acesso negado');
      }
    },
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => {
        this.accessForbidden();
      }, 1E3);
    }
  },
};
</script>
